import React from "react"
import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const ThankYou = () => (
  <Layout>
    <Navigation />
    <div className="h-full p-8 md:p-16 flex flex-col items-center justify-center container mx-auto">
      <h1 className="font-black text-4xl text-primary mb-8">ACM WAP Celebration Full Event</h1>
      <iframe
        id={"videoEmbed"}
        width="560" height="315"
        src="https://www.youtube.com/embed/pdobkhtK3iY"
        title="ACM WAP Celebration Full Event"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen />
    </div>
    <Footer />
  </Layout>
)

export default ThankYou